// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-three-city-effect-jsx": () => import("./../../../src/pages/three/cityEffect.jsx" /* webpackChunkName: "component---src-pages-three-city-effect-jsx" */),
  "component---src-pages-three-index-jsx": () => import("./../../../src/pages/three/index.jsx" /* webpackChunkName: "component---src-pages-three-index-jsx" */),
  "component---src-pages-three-scan-jsx": () => import("./../../../src/pages/three/scan.jsx" /* webpackChunkName: "component---src-pages-three-scan-jsx" */)
}

